
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['x-apikey'] = '123456';

let langPrefix = $('html').attr('lang') === 'ru' ? '/ru' : '';
let token = $('meta[name="csrf-token"]').attr('content');
require('jquery-validation');

import language from "./modules/lang";

function getFormData($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}
function formValidator(formName, info, callback) {
    
    let csrf = $('[name="csrf-token"]').attr('content');
    $.ajax({
        url: $(formName).attr('action'),
        type: "POST",
        data: getFormData($(formName)),
        headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "X-CSRF-TOKEN": csrf
        }, 
        beforeSend: function(){
            $('.preloader_container').css('display', 'flex');
            $('label span').remove();
            $(formName + ' ._error').removeClass('error');
            $(formName + ' ._error').removeClass('_error');
        },
        success: function (data) {
            $('.preloader_container').css('display', 'none');
            if (data.status == 'success') {
                $(formName).find('input[type="text"]').val('');
                $(formName).find('input[type="password"]').val('');
                switch($(formName).attr('name')){
                    case 'form_auth':
                        window.location.href = data.url;
                        break;
                    case 'form_registration':
                        $('#modal_registration .modal-body').addClass('d-none');
                        $('#modal_registration .modal-subject').removeClass('d-none');
                        break;
                    case 'form_forgot':
                        $('#modal_forgot .modal-body').addClass('d-none');
                        $('#modal_forgot .modal-subject').removeClass('d-none');
                        break;
                    case 'form_change_password':
                        $('#modal_changepasswd .modal-body').addClass('d-none');
                        $('#modal_changepasswd .modal-subject').removeClass('d-none');
                        break;
                }
            }
        },
        error: function (msg) {
            $('.preloader_container').css('display', 'none');
            switch($(formName).attr('name')){
                case 'form_auth':
                    $('form[name="'+ $(formName).attr('name') + '"]').find('.error_container').html('');
                    for (const [key, value] of Object.entries(msg.responseJSON.errors)) {
                        $('form[name="'+ $(formName).attr('name') + '"]').find('.error_container').append('<span>' + value + '</span>');
                    }
                    break;
                case 'form_registration':
                    for (const [key, value] of Object.entries(msg.responseJSON.errors)) {
                        $('label[for="'+ $(formName).attr('name') + '_' + key +'"]').addClass('_error error');
                        $('label[for="'+ $(formName).attr('name') + '_' + key +'"]').append('<span>' + value + '</span>');
                    }
                    break;
                case 'form_forgot':
                    for (const [key, value] of Object.entries(msg.responseJSON.errors)) {
                        $('form[name="'+ $(formName).attr('name') + '"]').find('.error_container').append('<span>' + value + '</span>');
                    }
                    break;
                case 'form_change_password':
                    for (const [key, value] of Object.entries(msg.responseJSON.errors)) {
                        $('form[name="'+ $(formName).attr('name') + '"]').find('.error_container').append('<span>' + value + '</span>');
                    }
                    break;
            }
            
        }
    });
}

$(document).on('submit', '.js-ajax_form_auth', function(e){
    e.preventDefault();    
    formValidator('#'+ $(this).attr('id'));
});